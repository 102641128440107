import { memo, useCallback, useState, useRef } from 'react';
import { useTranslations } from 'next-intl';
import useMount from 'hooks/use-mount';

import { Tag } from 'Components/Shared/UI';

import debounce from 'utils/debounce';

import useStyles from './styles';

const LEFT_RIGHT_PADDING = 50;
const LEFT_RIGHT_HIDDEN_AREA = 33;
const TAG_MARGIN_RIGHT = 5;
const SCROLL_STEP = 20;

const LabelPicker = ({ labels, isValidPath, currentLabelId }) => {
  const t = useTranslations();
  const styles = useStyles();

  const wrapperRef = useRef();
  const [mobileAllShow, setMobileAllShow] = useState(false);
  const [arrows, setArrows] = useState({ prev: false, next: false });

  const moveTo = dir => {
    const parentWidth = wrapperRef.current.getBoundingClientRect().width;
    const { scrollLeft } = wrapperRef.current;
    const childrenList = wrapperRef.current.children;
    let untilChildrenWidth = 0;
    let indexHalfChild = 0;
    while (
      untilChildrenWidth +
        childrenList[indexHalfChild].getBoundingClientRect().width +
        TAG_MARGIN_RIGHT <
      (dir === 'next'
        ? parentWidth - LEFT_RIGHT_HIDDEN_AREA + scrollLeft
        : LEFT_RIGHT_HIDDEN_AREA + scrollLeft)
    ) {
      untilChildrenWidth +=
        childrenList[indexHalfChild].getBoundingClientRect().width +
        TAG_MARGIN_RIGHT;
      indexHalfChild++;
    }

    const detectPrevEnd = () => {
      const halfPart =
        untilChildrenWidth +
        childrenList[indexHalfChild].getBoundingClientRect().width -
        scrollLeft;
      return scrollLeft - (parentWidth - halfPart);
    };
    const endScroll =
      dir === 'next' ? untilChildrenWidth : detectPrevEnd(scrollLeft);
    let progressScroll = scrollLeft;

    const scrollAnim = () => {
      if (dir === 'next') {
        if (progressScroll < endScroll - LEFT_RIGHT_PADDING) {
          if (progressScroll + SCROLL_STEP > endScroll - LEFT_RIGHT_PADDING)
            progressScroll = endScroll - LEFT_RIGHT_PADDING;
          else progressScroll += SCROLL_STEP;
          wrapperRef.current.scrollLeft = progressScroll;
          requestAnimationFrame(scrollAnim);
        } else {
          cancelAnimationFrame(scrollAnim);
        }
      } else if (progressScroll > endScroll + LEFT_RIGHT_PADDING) {
        if (progressScroll - SCROLL_STEP < endScroll + LEFT_RIGHT_PADDING)
          progressScroll = endScroll + LEFT_RIGHT_PADDING;
        else progressScroll -= SCROLL_STEP;
        wrapperRef.current.scrollLeft = progressScroll;
        requestAnimationFrame(scrollAnim);
      } else {
        cancelAnimationFrame(scrollAnim);
      }
    };
    requestAnimationFrame(scrollAnim);
  };

  const handleArrows = useCallback(() => {
    const childrenList = wrapperRef.current.children;
    const childrenWidth = Array.from(childrenList).reduce(
      (acc, item, index) => {
        acc +=
          item.getBoundingClientRect().width +
          (index < childrenList.length - 1 ? TAG_MARGIN_RIGHT : 0);
        return acc;
      },
      0,
    );
    const parentWidth = wrapperRef.current.getBoundingClientRect().width;
    setArrows({
      next: wrapperRef.current.scrollLeft + parentWidth <= childrenWidth - 2,
      prev: wrapperRef.current.scrollLeft > 0,
    });
  }, []);

  useMount(() => {
    if (wrapperRef.current) {
      const hasHorizontalScrollbar =
        wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth;
      if (hasHorizontalScrollbar) {
        setArrows({
          next: true,
          prev: wrapperRef.current.scrollLeft > 0,
        });
      }

      wrapperRef.current.addEventListener('scroll', debounce(handleArrows, 20));
    }
    return () => {
      wrapperRef.current?.removeEventListener('scroll', handleArrows);
    };
  });

  return (
    <>
      <div className={styles['label-picker-container']}>
        <div
          className={`${styles['picker-btns--block']} ${
            arrows.prev ? 'prev-pseudo' : ''
          } ${arrows.next ? 'next-pseudo' : ''}`}
        >
          {arrows.prev && (
            <span
              className="icon-Chevron---Left"
              onClick={() => moveTo('prev')}
            />
          )}
          {arrows.next && (
            <span
              className="icon-Chevron---Right"
              onClick={() => moveTo('next')}
            />
          )}
        </div>
        <div className={styles['labels-wrapper']} ref={wrapperRef}>
          {labels.map((label, index) => (
            <Tag
              count={label.count}
              key={index}
              isValidPath={isValidPath}
              label={label.t_label}
              labelId={label.id}
              selected={label.id === currentLabelId}
              text={t(label.label)}
              parentSlug={label.parentSlug}
              redirectionPath={label.redirectionPath}
              withX
              margin={[
                0,
                index < labels.length - 1 ? TAG_MARGIN_RIGHT : 0,
                0,
                0,
              ]}
            />
          ))}
        </div>
      </div>
      <div className={styles['label-picker-container__mobile']}>
        <div className={styles['labels-wrapper--mobile']}>
          {labels
            .slice(0, mobileAllShow ? labels.length : 3)
            .map((label, index) => (
              <Tag
                count={label.count}
                key={index}
                label={label.label}
                labelId={label.id}
                id={label.label}
                selected={label.id === currentLabelId}
                text={t(label.label)}
                withX
                margin={[
                  0,
                  0,
                  index < labels.length - 1 ? TAG_MARGIN_RIGHT : 0,
                  0,
                ]}
              />
            ))}
          <p
            className="lb-expanded-list"
            onClick={() => setMobileAllShow(!mobileAllShow)}
          >
            {mobileAllShow ? t('show_less_text') : t('show_all_labels')}
            <span
              className={
                !mobileAllShow ? 'icon-Accordion-Open' : 'icon-Accordion-Close'
              }
            ></span>
          </p>
        </div>
      </div>
    </>
  );
};
export default memo(LabelPicker);
